import { sortByCategory } from "./utils";

export const background_colors = {
    "": "#67B3EE",
    "all": "#FF5E5B",
    "general": "#FF5E5B",
    "world news": "#FF5E5B",
    "tragedy": "#FF5E5B",
    "accidents": "#FF5E5B",
    "health": "#FEC8FF",
    "science": "#D2FFD2",
    "technology": "#67B3EE",
    "politics": "#F9C784",
    "sports": "#FF9A5B",
    "sport": "#FF9A5B",
    "environment": "#6EEB83",
    "tech": "#67B3EE",
    "entertainment": "#E0A9FB",
    "business": "#96e8ff",
    "crime": "#C672BF",
    "transportation": "#FFD2D2",
    "human rights": "#FF5E5B",
    "gun control": "#9D847F",
    "us politics": "#F9C784",
    "social issues": "#FFD2FF",
    "cryptocurrency": "#96e8ff",
    "economy": "#D2FFD2",
    "automotive": "#FF9A5B",
    "disaster": "#FF5E5B",
    "legal": "#F9C784",
    "finance": "#96e8ff",
    "business & finance": "#96e8ff",
    "education & technology": "#67B3EE",
    "health & science": "#D2FFD2",
    "politics & economy": "#F9C784",
    "privacy & security": "#C672BF",
    "society": "#FFD2FF",
    "conflict": "#FF5E5B",
    "media": "#FFD2FF",
    "news": "#FF5E5B",
    "diplomacy": "#D2FFD2",
    "gaming": "#C07FF6",
    "art & entertainment": "#FFD2FF",
    "social media": "#F16286",
    "movies": "#C07FF6",
    "world": "#FF5E5B",
    "accidents & disasters": "#FF5E5B",
    "military": "#9D847F",
    "climate": "#6EEB83",
    "artificial intelligence": "#67B3EE",
    "gender": "#FEC8FF",
    "law": "#F9C784",
    "lifestyle": "#FFD2FF",
    "religion": "#C672BF",
    "arts": "#E0A9FB",
    "ai": "#67B3EE",
    "human interest": "#FF5E5B",
    "space": "#b669f5",
    "archeology": "#9D847F",
    "environmental issues": "#6EEB83",
    "travel": "#FFD2FF",
    "education": "#69E073",
    "auto": "#FF9A5B",
    "automobiles": "#FF9A5B",
    "automobile": "#FF9A5B",
    "culture": "#E0A9FB",
    "video games": "#C07FF6",
    "sci-tech": "#C2F3D6",
    "music": "#E0A9FB",
    "shopping": "#FFD2FF",
    "weather": "#6EEB83",
    "global news": "#FF5E5B",
    "arts and entertainment": "#C07FF6",
    "air quality": "#6EEB83",
    "history": "#9D847F",
    "jobs": "#F9C784",
    "legal affairs": "#F9C784",
    "robotic technology": "#67B3EE",
    "tech news": "#67B3EE",
    "astronomy": "#b669f5",
    "oceanography": "#3d9feb",
    "archaeology": "#9D847F",
    "cars": "#FF9A5B",
    "career": "#E0A9FB",
    "africa": "#FF5E5B",
    "bitcoin": "#96e8ff",
    "electric cars": "#67B3EE",
    "europe": "#FF5E5B",
    "home appliances": "#FFD2FF",
    "security": "#C672BF",
    "social networking": "#F16286",
    "cybersecurity": "#C672BF",
    "business/streaming": "#96e8ff",
    "entertainment/gaming": "#C07FF6",
    "lifestyle/shopping": "#FFD2FF",
    "technology/cybersecurity": "#C672BF",
    "technology/social media": "#F16286",
    "world/africa": "#FF5E5B",
    "world/politics": "#F9C784",
    "world/religion": "#C672BF",
    "world/science": "#D2FFD2",
    "home and kitchen": "#FFD2FF",
    "energy": "#6EEB83",
    "streaming": "#C07FF6",
    "esports": "#b669f5",
    "cycling": "#ffe45b",
    "justice/law": "#EDB392",
    "sales/deals": "#FFD2FF",
    "events/conferences": "#FFD2FF",
    "international news": "#FF5E5B",
    "international relations": "#FF5E5B",
    "privacy": "#C672BF",
    "international": "#FF5E5B",
    "australia": "#ffe45b",
    "uk": "#736B92",
    "us": "#736B92",
    "crime - uk": "#C672BF",
    "crime - us": "#C672BF",
    "environmental": "#6EEB83",
    "international - europe": "#FF5E5B",
    "world - india": "#FF5E5B",
    "world - australia": "#FF5E5B",
    "world - uk": "#FF5E5B",
    "world - us": "#FF5E5B",
    "world - latin america": "#FF5E5B",
    "chemistry": "#D2FFD2",
    "biology": "#D2FFD2",
    "earth science": "#6EEB83",
    "medical science": "#D2FFD2",
    "physics": "#b669f5",
    "space exploration": "#b669f5",
    "space science": "#b669f5",
    "gearth science": "#6EEB83",
    "palaeontology": "#9D847F",
    "engineering": "#67B3EE",
    "international conflict": "#FF5E5B",
    "international crime": "#C672BF",
    "science/environment": "#6EEB83",
    "business/economy": "#9FF0C0",
    "technology/privacy": "#C672BF",
    "world/latin america": "#FF5E5B",
    "world/us": "#FF5E5B",
    "world/uk": "#FF5E5B",
    "world/australia": "#FF5E5B",
    "world/asian": "#FF5E5B",
    "world/europe": "#FF5E5B",
    "world/asia": "#FF5E5B",
    "deals": "#FFD2FF",
    "photo gallery": "#FFD2FF",
    "latin america": "#FF5E5B",
    "us & canada": "#736B92",
    "art & culture": "#E0A9FB",
    "arts & culture": "#E0A9FB",
    "architecture": "#9D847F",
    "design": "#E0A9FB",
    "art": "#E0A9FB",
    "animals": "#ffe45b",
    "real estate": "#9D847F",
    "internet": "#67B3EE",
    "affirmative action": "#F9C784",
    "global affairs": "#FF5E5B",
    "parenting": "#FFD2FF",
    "food": "#FFD2FF",
}

export const ALL_SOURCES = [
    {'id': 'abc-news', 'name': 'ABC News', 'url': 'https://abcnews.go.com', 'category': 'general'},
    {'id': 'axios', 'name': 'Axios', 'url': 'https://www.axios.com', 'category': 'general'},
    {'id': 'blasting-news-br', 'name': 'Blasting News (BR)', 'url': 'https://br.blastingnews.com', 'category': 'general'},
    {'id': 'bleacher-report', 'name': 'Bleacher Report', 'url': 'http://www.bleacherreport.com', 'category': 'sports'},
    {'id': 'business-insider-uk', 'name': 'Business Insider (UK)', 'url': 'http://uk.businessinsider.com', 'category': 'business'},
    {'id': 'buzzfeed', 'name': 'Buzzfeed', 'url': 'https://www.buzzfeed.com', 'category': 'entertainment'},
    {'id': 'cbs-news', 'name': 'CBS News', 'url': 'http://www.cbsnews.com', 'category': 'general'},
    {'id': 'cnn', 'name': 'CNN', 'url': 'http://us.cnn.com', 'category': 'general'},
    {'id': 'crypto-coins-news', 'name': 'Crypto Coins News', 'url': 'https://www.ccn.com', 'category': 'technology'},
    {'id': 'engadget', 'name': 'Engadget', 'url': 'https://www.engadget.com', 'category': 'technology'},
    {'id': 'entertainment-weekly', 'name': 'Entertainment Weekly', 'url': 'http://www.ew.com', 'category': 'entertainment'},
    {'id': 'espn', 'name': 'ESPN', 'url': 'https://www.espn.com', 'category': 'sports'},
    {'id': 'financial-post', 'name': 'Financial Post', 'url': 'http://business.financialpost.com', 'category': 'business'},
    {'id': 'fortune', 'name': 'Fortune', 'url': 'http://fortune.com', 'category': 'business'},
    {'id': 'fox-news', 'name': 'Fox News', 'url': 'http://www.foxnews.com', 'category': 'general'},
    {'id': 'fox-sports', 'name': 'Fox Sports', 'url': 'http://www.foxsports.com', 'category': 'sports'},
    {'id': 'google-news', 'name': 'Google News', 'url': 'https://news.google.com', 'category': 'general'},
    {'id': 'hacker-news', 'name': 'Hacker News', 'url': 'https://news.ycombinator.com', 'category': 'technology'},
    {'id': 'ign', 'name': 'IGN', 'url': 'http://www.ign.com', 'category': 'entertainment'},
    {'id': 'marca', 'name': 'Marca', 'url': 'http://www.marca.com', 'category': 'sports'},
    {'id': 'mashable', 'name': 'Mashable', 'url': 'https://mashable.com', 'category': 'entertainment'},
    {'id': 'medical-news-today', 'name': 'Medical News Today', 'url': 'http://www.medicalnewstoday.com', 'category': 'health'},
    {'id': 'msnbc', 'name': 'MSNBC', 'url': 'http://www.msnbc.com', 'category': 'general'},
    {'id': 'national-geographic', 'name': 'National Geographic', 'url': 'http://news.nationalgeographic.com', 'category': 'science'},
    {'id': 'nbc-news', 'name': 'NBC News', 'url': 'http://www.nbcnews.com', 'category': 'general'},
    {'id': 'news24', 'name': 'News24', 'url': 'http://www.news24.com', 'category': 'general'},
    {'id': 'newsweek', 'name': 'Newsweek', 'url': 'https://www.newsweek.com', 'category': 'general'},
    {'id': 'new-york-magazine', 'name': 'New York Magazine', 'url': 'http://nymag.com', 'category': 'general'},
    {'id': 'next-big-future', 'name': 'Next Big Future', 'url': 'https://www.nextbigfuture.com', 'category': 'science'},
    {'id': 'politico', 'name': 'Politico', 'url': 'https://www.politico.com', 'category': 'general'},
    {'id': 'polygon', 'name': 'Polygon', 'url': 'http://www.polygon.com', 'category': 'entertainment'},
    {'id': 'reuters', 'name': 'Reuters', 'url': 'http://www.reuters.com', 'category': 'general'},
    {'id': 'talksport', 'name': 'TalkSport', 'url': 'http://talksport.com', 'category': 'sports'},
    {'id': 'techcrunch', 'name': 'TechCrunch', 'url': 'https://techcrunch.com', 'category': 'technology'},
    {'id': 'techradar', 'name': 'TechRadar', 'url': 'http://www.techradar.com', 'category': 'technology'},
    {'id': 'the-globe-and-mail', 'name': 'The Globe And Mail', 'url': 'https://www.theglobeandmail.com', 'category': 'general'},
    {'id': 'the-hill', 'name': 'The Hill', 'url': 'http://thehill.com', 'category': 'general'},
    {'id': 'the-huffington-post', 'name': 'The Huffington Post', 'url': 'http://www.huffingtonpost.com', 'category': 'general'},
    {'id': 'the-lad-bible', 'name': 'The Lad Bible', 'url': 'https://www.theladbible.com', 'category': 'entertainment'},
    {'id': 'the-next-web', 'name': 'The Next Web', 'url': 'http://thenextweb.com', 'category': 'technology'},
    {'id': 'the-sport-bible', 'name': 'The Sport Bible', 'url': 'https://www.thesportbible.com', 'category': 'sports'},
    {'id': 'the-verge', 'name': 'The Verge', 'url': 'http://www.theverge.com', 'category': 'technology'},
    {'id': 'the-washington-post', 'name': 'The Washington Post', 'url': 'https://www.washingtonpost.com', 'category': 'general'},
    {'id': 'time', 'name': 'Time', 'url': 'http://time.com', 'category': 'general'},
    {'id': 'vice-news', 'name': 'Vice News', 'url': 'https://news.vice.com', 'category': 'general'},
    {'id': 'wired', 'name': 'Wired', 'url': 'https://www.wired.com', 'category': 'technology'},
    {'id': 'bloomberg', 'name': 'Bloomberg', 'url': 'http://www.bloomberg.com', 'category': 'business'},
    {'id': 'business-insider', 'name': 'Business Insider', 'url': 'http://www.businessinsider.com', 'category': 'business'},
    {'id': 'the-wall-street-journal', 'name': 'The Wall Street Journal', 'url': 'http://www.wsj.com', 'category': 'business'},
    {'id': 'new-scientist', 'name': 'New Scientist', 'url': 'https://www.newscientist.com/section/news', 'category': 'science'},
];